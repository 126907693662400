import React from 'react'
import * as styles from "./ServicesPage.module.css";
import cx from "classnames";
import IconBasket from "../../assets/images/Icon.Basket.svg";
import IconUserId from "../../assets/images/Icon.UserId.svg";
import {SERVICES_PAGE_MOCK_DATA} from "./SERVICES_MOCK_DATA";
import ContactWithUs from "../ContactWithUs";

const ServicesPageComponent = () => {
  return (
    <div className={cx(styles.container, 'container')}>
      <h3 className={styles.sectionTitle}>Услуги</h3>

      <div className={styles.contentInfo}>
        {SERVICES_PAGE_MOCK_DATA.map((item) => (
          <article className={styles.contentItem}>
            <img aria-hidden className={styles.backgroundImage} src={item.icon} alt="Background icon"/>
            <div className={styles.itemTitleContainer}>
              <div aria-hidden className={styles.itemIcon}>
                <img aria-hidden src={item.icon} alt="Item icon"/>
              </div>
              <h5 className={styles.itemTitle}>{item.title}</h5>
            </div>

            <div className={styles.itemTextContainer}>
              <header>
                <span className={styles.itemDescriptionTitle}>{item.descriptionTitle}</span>
                <p className={styles.itemDescription}>{item.description}</p>
              </header>
              {item.additionalDescription && <p className={styles.itemDescription}>{item.additionalDescription}</p>}
            </div>
          </article>
        ))}
      </div>

      <ContactWithUs title="получить стоимость услуг" />
    </div>
  )
}

export default ServicesPageComponent
