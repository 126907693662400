import React from "react"
import { graphql } from "gatsby"
import { useTranslation } from "gatsby-plugin-react-i18next"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { renderHtmlBlocks } from "../helpers/render"
import Form from "../components/Form"
import { getCustomStyles } from "../helpers/styles"
import * as styles from "../componentsNonAdmin/Connections/Connections.module.css";
import IconBasket from "../assets/images/Icon.Basket.svg";
import IconUserId from "../assets/images/Icon.UserId.svg";
import cx from "classnames";
import ServicesPageComponent from "../componentsNonAdmin/Services/ServicesPage";

const ServicesPage = ({ data, location, pageContext }) => {
  const { t } = useTranslation()
  const siteTitle = data?.site?.siteMetadata?.title
  const { seo, settings } = data?.wpPage

  return (
    <Layout location={location} title={siteTitle}>
      <Seo
        title={
          seo?.title || t("Наши услуги - eRetail платформа DV Group")
        }
        description={seo?.metaDescription || ""}
        keywords={seo?.metaKeywords || ""}
      />
      {getCustomStyles(settings)}
      <div className="services-page">
        <article
          className="services-page-container"
          itemScope
          itemType="http://schema.org/Article"
        >
          <section itemProp="articleBody">
            <ServicesPageComponent />
          </section>
        </article>
      </div>
    </Layout>
  )
}

export default ServicesPage

export const pageQuery = graphql`
  query ($language: String!) {
    site {
      siteMetadata {
        title
      }
    }
    wpPage(slug: { eq: "services" }, status: { eq: "publish" }) {
      content
      seo {
        metaDescription
        metaKeywords
        title
      }
      settings {
        customStyles
        rbeaStyles
        language
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
